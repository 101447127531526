<template>
  <span class="v-footer-contact-hours">
    <span class="v-footer-contact-hours__title" @click="toggle()">
      <slot></slot>
      <span class="v-footer-contact-hours__caret">
        <svg width="12" height="7" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M1 1l4.679 4.862a.442.442 0 0 0 .642 0L11 1"
            stroke="currentColor"
            stroke-width="1.5"
            fill="none"
            fill-rule="evenodd"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </span>
    </span>
    <div v-if="showDetailed" class="v-footer-contact-hours__detailed">
      <div class="v-footer-contact-hours__detailed__wrapper">
        <div
          v-for="(day, index) in weeklyHours"
          :key="index"
          :class="[
            'd-flex flex-column flex-sm-row mt-1 mt-sm-0 mb-sm-2',
            !!day.today && 'text-primary'
          ]"
        >
          <div
            class="d-flex d-sm-inline-flex v-footer-contact-hours__detailed__day"
          >
            {{ day.name }}
          </div>
          <div
            class="d-flex d-sm-inline-flex flex-grow-1 justify-content-sm-end"
          >
            {{ day.hours }}
          </div>
        </div>
      </div>
    </div>
  </span>
</template>

<script>
export default {
  props: {
    weeklyHours: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      showDetailed: false
    };
  },
  methods: {
    toggle() {
      this.showDetailed = !this.showDetailed;
    },
    closeOnOutsideClick(event) {
      if (!this.$el.contains(event.target)) {
        this.showDetailed = false;
      }
    }
  },
  mounted() {
    document.addEventListener("mousedown", this.closeOnOutsideClick, false);
  },
  beforeDestroy() {
    document.removeEventListener("mousedown", this.closeOnOutsideClick, false);
  }
};
</script>

<style scoped lang="scss">
@import "../../sass/component.scss";

.v-footer-contact-hours {
  position: relative;

  &__title {
    display: inline-flex;
    position: relative;
    color: theme-color("primary");
    user-select: none;
    cursor: pointer;
  }

  &__caret {
    display: flex;
    align-items: center;
    margin-left: 5px;
    pointer-events: none;
  }

  &__detailed {
    position: absolute;
    bottom: 0;
    left: 0;
    transform: translateY(100%);
    z-index: 1000;
    width: calc(100vw - 3rem);

    @include media-breakpoint-up(md) {
      left: 50%;
      transform: translate(-50%, 100%);
      width: auto;
      min-width: 335px;
    }

    &__day {
      font-weight: bold;

      @include media-breakpoint-up(sm) {
        font-weight: normal;
      }
    }

    &__wrapper {
      margin-top: $spacer;
      background: #fff;
      border-radius: $border-radius;
      padding: $spacer;
      box-shadow: 0 18px 40px 0 rgba(0, 0, 0, 0.3);
      position: relative;
      animation-name: reveal;
      animation-duration: 200ms;
      animation-timing-function: ease-out;

      &::before {
        content: "";
        width: 20px;
        height: 20px;
        background: #ffff;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -40%) rotate(45deg);
      }
    }
  }
}
</style>
