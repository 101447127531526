var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"waypoint",rawName:"v-waypoint",value:({
    active: true,
    callback: _vm.onWaypoint,
    options: _vm.intersectionOptions
  }),expression:"{\n    active: true,\n    callback: onWaypoint,\n    options: intersectionOptions\n  }"}],class:[
    'v-table',
    'v-table--vue',
    `v-table--${_vm.id}`,
    _vm.overflow && 'v-table--overflow',
    _vm.fontSize === 'small' && 'v-table--font-size-small'
  ]},[_c('style',{tag:"component",domProps:{"innerHTML":_vm._s(_vm.style)}}),_c('div',{ref:"table",staticClass:"v-table__table"},[_c('table',[_c('thead',[_c('tr',{staticClass:"v-row"},_vm._l((_vm.columns),function(col,index){return _c('th',{key:index,class:[
              'v-th',
              !_vm.isMobileColumn(col) && 'v-th--hide',
              _vm.isSortColumn(col) && 'sortable'
            ],on:{"click":function($event){return _vm.columnSort(col, $event)}}},[_c('v-text',{staticClass:"text-gray-dark py-1 px-3",attrs:{"font-size":"2"}},[_vm._v(" "+_vm._s(col.text || col)+" "),(_vm.columnLoading === col)?_c('v-loading',{attrs:{"small":""}}):(_vm.isSortColumn(col))?_c('v-arrow',{attrs:{"direction":_vm.sortDirection(col)}}):_vm._e()],1)],1)}),0)]),_c('tbody',[_vm._t("default")],2)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }