<template>
  <a
    v-if="!this.$slots.items"
    :class="[
      'v-navbar-navigation-item',
      { 'v-navbar-navigation-item--active': active }
    ]"
    v-on="$listeners"
  >
    <slot name="default"></slot>
  </a>
  <v-navbar-menu-item
    v-else
    v-on="$listeners"
    v-bind="$attrs"
    :active="active"
    navigation
  >
    <slot name="default"></slot>
    <template v-slot:items>
      <slot name="items"></slot>
    </template>
  </v-navbar-menu-item>
</template>

<script>
export default {
  props: {
    active: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../../sass/component.scss";

.v-navbar-navigation-item {
  white-space: nowrap;
  text-decoration: none;
  color: theme-color("dark");
  padding: 10px 11px;
  margin: 0;
  font-size: 0.875rem;
  /* outline: 0; */
  touch-action: manipulation;
  -webkit-tap-highlight-color: transparent;

  @include media-breakpoint-up(md) {
    font-size: 1rem;
    padding: 2px $spacer 0 $spacer;

    &.special-navbar-button {
      display: inline-block;
      border-radius: 100px;
      font-weight: bold;
      color: #fff !important;
      box-shadow: 0 0 0 $spacer * 0.5 theme-color("primary");
      transition: background-color 200ms ease-in-out;
      background: theme-color("primary");
      user-select: none;
      &:hover {
        background-color: theme-color("primary");
        filter: brightness(0.95);
      }
      &:active {
        background-color: theme-color("primary");
        filter: brightness(0.8);
      }
      &:not(:first-child) {
        margin-left: $spacer * 0.75;
      }
      &:not(:last-child) {
        margin-right: $spacer * 0.75;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    margin: 0 $spacer * 0.25;
  }

  &:active,
  &:hover,
  &--active {
    color: theme-color("primary");
  }

  &--active {
    font-weight: bold;
  }
}
</style>
