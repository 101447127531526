<template>
  <li
    v-if="hasItems"
    :class="[
      'v-navbar-menu-item',
      horizontal && 'v-navbar-menu-item--horizontal',
      isOpen && 'v-navbar-menu-item--open',
      navigation && 'v-navbar-menu-item--navigation',
      active && 'v-navbar-menu-item--active'
    ]"
    tabindex="-1"
  >
    <div class="v-navbar-menu-item__link" @click="toggle()" v-on="$listeners">
      <img v-if="language" :src="icon" alt="navigation icon" />
      <v-navbar-icon v-else :icon="icon" />
      <a v-bind="{ href, target, title, rel }" tabindex="0">
        <span><slot name="default"></slot></span>
        <v-navbar-icon icon="caret" class="v-navbar-menu-item__arrow" />
      </a>
    </div>
    <div class="v-navbar-menu-item__items">
      <ul class="v-navbar-menu-item__items__wrapper">
        <slot name="items"></slot>
      </ul>
    </div>
  </li>
  <li
    v-else
    :class="[
      'v-navbar-menu-item',
      {
        'v-navbar-menu-item__locked': !icon && requireAuth,
        'v-navbar-menu-item--active': active
      }
    ]"
    tabindex="-1"
  >
    <a
      v-bind="{ href, target, title, rel }"
      class="v-navbar-menu-item__link"
      v-on="$listeners"
      @click="showSidebar"
      tabindex="0"
    >
      <v-navbar-icon :icon="icon" />
      <span>
        <v-icon
          v-if="!icon && requireAuth"
          name="lock"
          class="v-navbar-menu-item__lock"
          width="15"
          height="18"
        ></v-icon>
        <slot name="default"></slot>
      </span>
    </a>
  </li>
</template>

<script>
import vNavbarIcon from "../v-navbar-icon";

export default {
  components: { "v-navbar-icon": vNavbarIcon },
  data() {
    return {
      isOpen: false
    };
  },
  props: {
    icon: {
      type: String,
      default: null
    },
    href: {
      type: String,
      default: null
    },
    target: {
      type: String,
      default: null
    },
    title: {
      type: String,
      default: null
    },
    rel: {
      type: String,
      default: null
    },
    horizontal: {
      type: Boolean,
      default: false
    },
    language: {
      type: Boolean,
      default: false
    },
    navigation: {
      type: Boolean,
      default: false
    },
    active: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    toggle() {
      this.isOpen = !this.isOpen;
    },
    closeOnOutsideClick(event) {
      if (!this.$el.contains(event.target)) {
        this.isOpen = false;
      }
    },
    showSidebar() {
      if (this.icon === "menu") {
        this.$root.$emit("show-sidebar");
      }
    }
  },
  computed: {
    hasItems() {
      return !!this.$slots.items || !!this.$scopedSlots.items;
    },
    requireAuth() {
      return !!this.$props.href && /SC_LOCKED/.test(this.$props.href);
    }
  },
  mounted() {
    document.addEventListener("mousedown", this.closeOnOutsideClick, false);
  },
  beforeDestroy() {
    document.removeEventListener("mousedown", this.closeOnOutsideClick, false);
  }
};
</script>

<style scoped lang="scss">
@import "../../../sass/component.scss";

.v-navbar-menu-item {
  position: relative;
  user-select: none;
  margin-left: $spacer;
  list-style: none; // remove bullets

  &:first-child,
  .v-navbar-menu-item & {
    margin-left: 0;
  }

  &__items {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 100%);
    z-index: 1001;

    &__wrapper {
      background: #fff;
      border-radius: $border-radius;
    }
  }

  &__link {
    cursor: pointer;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: theme-color("dark");
    touch-action: manipulation;
    -webkit-tap-highlight-color: transparent;
    font-size: 0.875rem;

    @include media-breakpoint-down(sm) {
      font-size: 0.75rem;
      line-height: 1em;

      span,
      a {
        margin-top: 3px;
      }
    }

    .v-navbar-menu-item__locked & {
      color: theme-color("gray-dark");
    }

    ::v-deep {
      img,
      .img {
        background-size: contain;
        margin-bottom: 1px;
        width: 30px;
        height: 30px;

        @include media-breakpoint-down(sm) {
          width: 30px;
          height: 30px;
        }
      }
    }

    a {
      display: flex;
      white-space: nowrap;
      color: inherit;
      text-decoration: none;
      align-content: center;
    }

    svg.v-navbar-menu-item__arrow {
      position: relative;
      top: -1px;
      margin-left: 2px;
      align-self: center;
    }

    svg.v-navbar-menu-item__arrow {
      position: relative;
      top: -1px;
      margin-left: 5px;
    }

    .v-navbar-menu-item__lock {
      position: relative;
      top: 2px;
      margin-right: 5px;
      width: 15px;
      height: 18px;
    }
  }

  &__close {
    height: 30px;
    width: 30px;
  }

  &__language {
    transform: scale(1.1);
  }

  &--navigation &__link {
    font-size: 1rem;
    padding: 2px 1rem 0 1rem;
  }

  &--active > &__link,
  &--active > &__link a {
    font-weight: bold;
    color: theme-color("primary");
  }

  &__items {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 100%);
    display: none;

    @media screen and (max-width: 1420px) {
      transform: translate(-85%, 100%);
    }

    .v-navbar-menu-item--open & {
      display: block;

      @include media-breakpoint-up(lg) {
        display: none;
      }
    }

    .v-navbar-menu-item:hover &,
    .v-navbar-menu-item:focus &,
    .v-navbar-menu-item:focus-within & {
      @include media-breakpoint-up(lg) {
        display: block;
      }
    }

    &__wrapper {
      margin-top: $spacer;
      min-width: 220px;
      background: #fff;
      border-radius: $border-radius;
      padding: $spacer;
      box-shadow: 0 18px 40px 0 rgba(0, 0, 0, 0.3);
      position: relative;
      animation-name: reveal;
      animation-duration: 200ms;
      animation-fill-mode: both;
      animation-timing-function: ease-out;

      @include media-breakpoint-up(lg) {
        animation-delay: 100ms;
      }

      &::before {
        content: "";
        width: 20px;
        height: 20px;
        background: #ffff;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -40%) rotate(45deg);
      }

      @media screen and (max-width: 1420px) {
        &::before {
          left: 85%;
        }
      }
    }
  }

  &__items &__link {
    display: block;
    font-size: 1rem;
    text-align: left;
    transition: transform 200ms ease-out;
    padding: $spacer * 0.5 0;

    &:hover {
      color: theme-color("primary");
    }
  }

  &.v-navbar-menu-item--horizontal {
    display: inline-flex;

    .v-navbar-menu-item {
      &__link {
        flex-direction: row;

        img {
          width: 20px;
          height: 20px;
          margin-bottom: 0;
          margin-right: 5px;
        }
      }

      &__items {
        transform: translate(-50%, -25px);
        text-align: left;

        &__wrapper {
          margin-top: 0;
          margin-bottom: $spacer;
          animation-name: reveal-up;

          &::before {
            content: "";
            width: 20px;
            height: 20px;
            background: #ffff;
            position: absolute;
            bottom: 0;
            top: auto;
            left: 50%;
            transform: translate(0, 40%) rotate(45deg);
          }
        }
      }
    }
  }
}
</style>
