import vNavbar from "./v-navbar.vue";
import vNavbarLogo from "./v-navbar-logo.vue";
import vNavbarNavigation from "./navigation/v-navbar-navigation.vue";
import vNavbarNavigationItem from "./navigation/v-navbar-navigation-item.vue";
import vNavbarMenu from "./menu/v-navbar-menu.vue";
import vNavbarMenuItem from "./menu/v-navbar-menu-item.vue";
import vNavbarSearch from "./v-navbar-search.vue";
import vNavbarNotifications from "./notifications/v-navbar-notifications.vue";
import vNavbarNotificationsItem from "./notifications/v-navbar-notifications-item.vue";

export default Vue => {
  // Wrapper
  Vue.component("v-navbar", vNavbar);

  // Logo
  Vue.component("v-navbar-logo", vNavbarLogo);

  // Navigation
  Vue.component("v-navbar-navigation", vNavbarNavigation);
  Vue.component("v-navbar-navigation-item", vNavbarNavigationItem);

  // Menu
  Vue.component("v-navbar-menu", vNavbarMenu);
  Vue.component("v-navbar-menu-item", vNavbarMenuItem);

  // Search
  Vue.component("v-navbar-search", vNavbarSearch);

  // Notifications
  Vue.component("v-navbar-notifications", vNavbarNotifications);
  Vue.component("v-navbar-notifications-item", vNavbarNotificationsItem);
};
