<template>
  <div :class="['v-toggle']">
    <label class="v-toggle__wrapper">
      <input
        class="v-toggle__input"
        type="checkbox"
        v-bind="$attrs"
        v-on="inputListeners"
        :checked="checked"
        :disabled="loading"
      />
      <span class="v-toggle__label">{{ placeholder }}</span>
      <div
        v-if="!loading"
        :class="['v-toggle__toggle', primary && 'v-toggle__toggle--primary']"
      ></div>
      <v-loading v-if="loading" class="v-toggle__loading" large></v-loading>
    </label>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    placeholder: {
      type: String,
      default: null
    },
    checked: {
      type: Boolean,
      default: null
    },
    primary: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    inputListeners() {
      return {
        ...this.$listeners,
        input: event => event.preventDefault(),
        change: event => {
          if (event.srcElement.checked == true) {
            this.$emit("on");
          } else if (event.srcElement.checked == false) {
            this.$emit("off");
          }
          this.$emit("input", event);
        }
      };
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../sass/component.scss";

.v-toggle {
  &__label {
    margin-right: $spacer;
    color: theme-color("placeholder");
    flex-grow: 1;
  }

  &__wrapper {
    position: relative;
    display: flex;
    align-items: center;
    user-select: none;
  }

  &__input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;

    &:checked ~ .v-toggle__toggle {
      background: theme-color("focus");
      border-color: theme-color("focus");

      &--primary {
        background: theme-color("primary");
        border-color: theme-color("primary");
      }

      &::before {
        transform: translateX(20px);
      }
    }

    &:checked:disabled ~ .v-toggle__toggle {
      background: #ccc;
      border-color: theme-color("border");

      svg {
        stroke: theme-color("border");
      }
    }

    &:disabled ~ .v-toggle__toggle {
      cursor: not-allowed;
    }

    &:disabled ~ .v-toggle__label {
      cursor: not-allowed;
      color: theme-color("border");
    }

    &:checked ~ .v-toggle__label {
      color: inherit;
    }

    &:checked:disabled ~ .v-toggle__label {
      color: theme-color("border");
    }
  }

  &__loading {
    position: relative;
    padding: 0 10px;
  }

  &__toggle {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 30px;
    flex-shrink: 0;
    border-radius: 15px;
    background: #fff;
    border: 1px solid theme-color("border");
    transition: background-color 200ms ease-in-out, border 200ms ease-in-out;
    cursor: pointer;
    /* overflow: hidden; */

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 28px;
      height: 28px;
      border-radius: 14px;
      background-color: #fff;
      box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.5);
      transition: transform 200ms ease-in-out;
    }
  }
}
</style>
