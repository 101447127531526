var render = function render(){var _vm=this,_c=_vm._self._c;return _c('header',{class:[
    'v-hero',
    {
      'v-hero--with-image': !!_vm.imgSrc,
      'v-hero--blur': !!_vm.imgSrc && _vm.blur,
      'v-hero--auto-height': !!_vm.autoHeight,
      'v-hero--small': !!_vm.small,
      'v-hero--minimal': !!_vm.minimal,
      'v-hero--split': !!_vm.split,
      'split-scrolled': _vm.isScrolling && _vm.split
    }
  ]},[(_vm.minimal)?_c('div',{staticClass:"v-hero__content container-fluid"},[_vm._t("default")],2):(_vm.split && !_vm.video)?_c('div',{class:[
      'v-hero__container--image',
      { 'split-scrolled': _vm.isScrolling && _vm.split }
    ]},[(_vm.imgSrc)?_c('img',{staticClass:"v-hero--split__media v-hero--split__media--image",class:{ 'split-scrolled': _vm.isScrolling && _vm.split },attrs:{"src":_vm.imgSrc,"alt":_vm.alt}}):_vm._e(),_c('div',{staticClass:"v-hero--split__content",class:{ 'split-scrolled': _vm.isScrolling && _vm.split }},[_vm._t("default")],2)]):(_vm.split && _vm.video)?_c('div',{staticClass:"container-fluid"},[_vm._t("default")],2):_c('div',{class:[
      'v-hero__image',
      {
        'v-hero__image--blur': !!_vm.imgSrc && _vm.blur
      }
    ]},[_c('picture',{staticClass:"v-hero__picture"},[(_vm.imgSrc)?_c('source',{attrs:{"srcset":_vm.resolveUrl(_vm.imgSrc),"media":"(max-width: 767px)"}}):_vm._e(),(_vm.imgMdSrc || _vm.imgSrc)?_c('source',{attrs:{"srcset":_vm.resolveUrl(_vm.imgMdSrc || _vm.imgSrc),"media":"(min-width: 768px)"}}):_vm._e(),(_vm.imgSrc)?_c('img',{staticClass:"v-hero__image--fallback",attrs:{"src":_vm.resolveUrl(_vm.imgSrc),"alt":_vm.alt}}):_vm._e()]),(_vm.blur)?_c('div',{staticClass:"v-hero__blur-overlay"}):_vm._e(),(_vm.hasContent)?_c('div',{class:[
        'v-hero__content container-fluid',
        {
          'v-hero__content--blur': !!_vm.imgSrc && _vm.blur
        }
      ]},[_vm._t("default")],2):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }