<!-- .vuepress/components/Promo.vue -->
<template>
  <div class="promo">
    <div class="promo__inner">
      <div class="promo__image">
        <img :src="imageSource" alt="Promotion" />
      </div>
      <div class="promo__content">
        <v-text tag="h2" heading size="large" class="promo__title">
          {{ title }}
        </v-text>
        <v-text class="promo__description">{{ description }}</v-text>
        <v-button class="promo__button">{{ buttonText }}</v-button>
        <v-button link class="promo__link">{{ linkText }}</v-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Promo",
  props: {
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    },
    buttonText: {
      type: String,
      required: true
    },
    linkText: {
      type: String,
      required: true
    },
    desktopImageSrc: {
      type: String,
      required: true
    },
    mobileImageSrc: {
      type: String,
      required: true
    }
  },
  computed: {
    imageSource() {
      const isMobile =
        typeof window !== "undefined" ? window.innerWidth <= 767 : false;
      return isMobile ? this.mobileImageSrc : this.desktopImageSrc;
    }
  }
};
</script>

<style lang="scss" scoped>
.promo {
  max-width: calc(1280px - 3rem);
  margin-inline: auto;

  &__inner {
    display: flex;
    align-items: center;
  }

  &__image {
    flex: 0 0 auto;
    margin-right: 1rem;

    img {
      max-width: 100%;
      height: auto;
      max-height: 400px; /* Add this line to control the image height */
    }
  }

  &__content {
    flex: 1 1 auto;
  }

  &__description {
    margin-bottom: 1rem;
  }

  &__button {
    background-color: #ff0000;
    color: #ffffff;
    border: none;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    cursor: pointer;
  }

  &__link {
    color: #0000ff;
    text-decoration: underline;
    margin-left: 1rem;
  }
}
</style>
