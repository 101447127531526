var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'v-checkbox',

    // Add padding for word wrap if there is a tooltip
    { 'pr-3': !!_vm.tooltip },

    _vm.showError && (!!_vm.error || !!_vm.errorSlot) && 'v-checkbox--error'
  ]},[_c('label',{staticClass:"v-checkbox__wrapper"},[_c('input',_vm._g(_vm._b({staticClass:"v-checkbox__input",attrs:{"type":"checkbox"},domProps:{"checked":_vm.checked},on:{"invalid":_vm.onInvalid,"change":function($event){$event.stopPropagation();return _vm.onChange.apply(null, arguments)}}},'input',_vm.$attrs,false),_vm.inputListeners)),_c('div',{staticClass:"v-checkbox__checkbox"},[_c('svg',{attrs:{"width":"16","height":"12","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M2 6l4 4 8-8","stroke-width":"2","fill":"none","fill-rule":"evenodd","stroke-linecap":"square"}})])]),_c('span',{class:['v-checkbox__label', !_vm.small && 'ml-3', _vm.small && 'ml-2']},[(_vm.hasContent)?_vm._t("default"):_c('v-text',{attrs:{"tag":"span","size":_vm.small ? 'small' : null}},[_vm._v(_vm._s(_vm.placeholder))])],2)]),(_vm.showError || _vm.description)?_c('div',{class:['v-checkbox__helper']},[_vm._v(" "+_vm._s(_vm.showError ? _vm.error || _vm.errorSlot : _vm.description)+" ")]):_vm._e(),(!_vm.mounted)?_c('div',{ref:"error"},[_vm._t("error")],2):_vm._e(),(_vm.tooltip)?_c('v-infoicon',{attrs:{"tooltip":_vm.tooltip,"offset":"0px"}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }