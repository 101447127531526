var render = function render(){var _vm=this,_c=_vm._self._c;return _c(_vm.tagName,_vm._g({tag:"component",class:[
    // Base class
    'v-text',

    // Add padding for word wrap if there is a tooltip
    _vm.tooltip && 'pr-3',

    // Font-size classes
    !_vm.size && `v-text--size-${_vm.fontSize}`,
    !_vm.heading && _vm.size === 'small' && `v-text--size-2`,
    !_vm.heading && _vm.size === 'medium' && `v-text--size-3`,
    !_vm.heading && _vm.size === 'large' && `v-text--size-3 v-text--size-md-4`,
    _vm.heading && _vm.size === 'xsmall' && `v-text--size-4 v-text--size-md-5`,
    _vm.heading && _vm.size === 'small' && `v-text--size-5 v-text--size-md-6`,
    _vm.heading && _vm.size === 'medium' && `v-text--size-6 v-text--size-md-7`,
    _vm.heading && _vm.size === 'large' && `v-text--size-7 v-text--size-md-8`,
    _vm.heading && _vm.size === 'xlarge' && `v-text--size-8 v-text--size-md-9`,
    _vm.heading && _vm.size === 'frontpage' && `v-text--frontpage`,
    !!_vm.fontMdSize && `v-text--size-md-${_vm.fontMdSize}`,
    !!_vm.fontLgSize && `v-text--size-lg-${_vm.fontLgSize}`,

    // Font weight and italic
    (_vm.bold || _vm.heading) && 'font-weight-bold',
    _vm.italic && 'font-italic',

    // Linebreaks
    _vm.linebreaks && 'v-text--linebreaks',

    // Page breaks
    _vm.pageBreak && 'v-text--pagebreak'
  ]},_vm.$listeners),[(_vm.id && ['h2', 'h3', 'h4'].includes(_vm.tag))?_c('v-anchor',{attrs:{"id":_vm.id}}):_vm._e(),(_vm.animatedLists.length > 0)?_c('div',{directives:[{name:"waypoint",rawName:"v-waypoint",value:({
      active: true,
      callback: _vm.onWaypoint,
      options: _vm.intersectionOptions
    }),expression:"{\n      active: true,\n      callback: onWaypoint,\n      options: intersectionOptions\n    }"}]}):_vm._e(),(_vm.truncateSlot)?_c('v-truncate',{attrs:{"middle":_vm.truncateMiddle}},[_vm._t("default")],2):(_vm.lorem)?[_vm._v(" Lorem ipsum dolor sit amet, elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nisi ut aliquip ex ea commodo consequat. ")]:(_vm.loremipsum)?[_vm._v(" Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. ")]:_vm._t("default"),(_vm.tooltip)?_c('v-infoicon',{staticClass:"v-text__tooltip",attrs:{"tooltip":_vm.tooltip,"offset":"0px"}}):_vm._e(),(_vm.tagName === 'a')?_c('v-icon',{staticClass:"v-text__icon--link",attrs:{"name":"link","size":"xxsmall"}}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }