<template>
  <div
    :class="[
      'v-checkbox-group',
      showError && !!errorSlot && 'v-checkbox-group--error'
    ]"
  >
    <slot></slot>
    <div :class="['v-checkbox-group__helper']">
      {{ showError ? errorSlot : "" }}
    </div>
    <div v-if="!mounted" ref="error">
      <slot name="error"></slot>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      errorSlot: false,
      mounted: false,
      showError: false
    };
  },
  mounted() {
    const error = this.$refs.error.innerText;

    if (error) {
      this.errorSlot = error.trim();
      this.showError = true;
    }

    this.mounted = true;
  }
};
</script>

<style scoped lang="scss">
@import "../../sass/component.scss";

.v-checkbox-group {
  &__helper {
    margin-top: 5px;
    font-size: 0.75rem;
    color: theme-color("placeholder");

    .v-checkbox-group--error & {
      color: theme-color("danger");
    }
  }

  &--error ::v-deep .v-checkbox__checkbox {
    border-color: theme-color("danger");
  }
}
</style>
