<template>
  <div
    :class="[
      'v-checkbox',

      // Add padding for word wrap if there is a tooltip
      { 'pr-3': !!tooltip },

      showError && (!!error || !!errorSlot) && 'v-checkbox--error'
    ]"
  >
    <label class="v-checkbox__wrapper">
      <input
        class="v-checkbox__input"
        type="checkbox"
        v-bind="$attrs"
        v-on="inputListeners"
        :checked="checked"
        @invalid="onInvalid"
        @change.stop="onChange"
      />
      <div class="v-checkbox__checkbox">
        <svg width="16" height="12" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M2 6l4 4 8-8"
            stroke-width="2"
            fill="none"
            fill-rule="evenodd"
            stroke-linecap="square"
          />
        </svg>
      </div>
      <span :class="['v-checkbox__label', !small && 'ml-3', small && 'ml-2']">
        <slot v-if="hasContent"></slot>
        <v-text tag="span" :size="small ? 'small' : null" v-else>{{
          placeholder
        }}</v-text>
      </span>
    </label>
    <div v-if="showError || description" :class="['v-checkbox__helper']">
      {{ showError ? error || errorSlot : description }}
    </div>
    <div v-if="!mounted" ref="error">
      <slot name="error"></slot>
    </div>
    <v-infoicon v-if="tooltip" :tooltip="tooltip" offset="0px"></v-infoicon>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    placeholder: {
      type: String,
      default: null
    },
    checked: {
      type: Boolean,
      default: null
    },
    error: {
      type: String,
      default: null
    },
    description: {
      type: String,
      default: null
    },
    invalid: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    },
    tooltip: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      errorSlot: false,
      mounted: false,
      showError: false
    };
  },
  created() {
    this.showError = this.invalid !== false;
  },
  mounted() {
    const error = this.$refs.error.innerText;

    if (error) {
      this.errorSlot = error.trim();
      this.showError = true;
    }

    this.mounted = true;
  },
  methods: {
    onInvalid(event) {
      event.preventDefault();
      this.showError = true;
    },
    onChange(event) {
      // Hide error if shown
      if (this.showError) {
        this.showError = false;
      }
    }
  },
  computed: {
    inputListeners() {
      return {
        ...this.$listeners,
        input: event => event.preventDefault(),
        change: event => this.$emit("input", event)
      };
    },
    hasContent() {
      return !!this.$slots.default;
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../sass/component.scss";

.v-checkbox {
  cursor: default;
  position: relative;

  &__wrapper {
    position: relative;
    display: flex;
    user-select: none;
  }

  &__input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    width: 24px;
    height: 24px;
    margin: 0;

    &:checked ~ .v-checkbox__checkbox {
      background: theme-color("focus");
      border-color: theme-color("focus");

      svg {
        display: block;
        stroke: #fff;
      }
    }

    &:checked:disabled ~ .v-checkbox__checkbox {
      background: #fff;
      border-color: theme-color("border");
      cursor: not-allowed;

      svg {
        stroke: theme-color("border");
      }
    }

    &:checked ~ .v-checkbox__label {
      color: inherit;
    }

    &:checked:disabled ~ .v-checkbox__label {
      color: theme-color("border");
      cursor: not-allowed;
    }
  }

  &__checkbox {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    flex-basis: 24px;
    flex-shrink: 0;
    border-radius: 5px;
    background: #fff;
    border: 1px solid theme-color("border");

    .v-checkbox--error & {
      border-color: theme-color("danger");
    }

    svg {
      display: none;
    }
  }

  &__label {
    color: theme-color("placeholder");
  }

  &__helper {
    margin-top: 5px;
    font-size: 0.75rem;
    color: theme-color("placeholder");

    .v-checkbox--error & {
      color: theme-color("danger");
    }
  }
}
</style>
