var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"v-pagination"},[_c('div',{staticClass:"v-pagination__show"},[_vm._v(" "+_vm._s(_vm.$t("Show", "components.datatable.show"))+" ")]),_c('v-form',{staticClass:"v-pagination__pagesize"},[_c('v-select',{attrs:{"small":""},on:{"input":function($event){return _vm.setPageSize($event.target.value)}},model:{value:(_vm.currentPageSize),callback:function ($$v) {_vm.currentPageSize=$$v},expression:"currentPageSize"}},[_c('v-option',{attrs:{"value":"5"}},[_vm._v("5")]),_c('v-option',{attrs:{"value":"10"}},[_vm._v("10")]),_c('v-option',{attrs:{"value":"25"}},[_vm._v("25")]),_c('v-option',{attrs:{"value":"50"}},[_vm._v("50")])],1)],1),_c('div',{staticClass:"v-pagination__itemnumbers"},[_vm._v(_vm._s(_vm.itemNumbers))]),_c('div',{staticClass:"v-pagination__arrows"},[_c('v-arrow',{class:[
        'v-pagination__arrow',
        _vm.firstPage && 'v-pagination__arrow--disabled'
      ],attrs:{"direction":"left"},on:{"click":function($event){return _vm.navigate(false, _vm.total)}}}),_c('v-arrow',{class:[
        'v-pagination__arrow',
        _vm.lastPage && 'v-pagination__arrow--disabled'
      ],attrs:{"direction":"right"},on:{"click":function($event){return _vm.navigate(true, _vm.total)}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }