<template>
  <div
    :class="['v-navbar', alerts && 'v-navbar--alerts']"
    :style="[hidden && { transform: `translateY(-${this.hiddenHeight()}px)` }]"
  >
    <div :class="['v-navbar__alerts', 'v-navbar__alerts--expanded']">
      <slot name="alerts"></slot>
    </div>
    <div class="v-navbar__content container-fluid">
      <div class="row justify-content-between align-items-center h-100">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import debounce from "lodash.debounce";

const NAV_HEIGHT = 61;
const NAV_HEIGHT_MOBILE = 107;

export default {
  props: {
    alerts: {
      type: Boolean,
      default: false
    },
    mobile: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      hidden: false,
      hiddenAlerts: false,
      scrollTop: 0,
      prevScrollTop: 0
    };
  },
  methods: {
    onMobileScroll: debounce(
      function() {
        this.handleMobileScroll();
      },
      100,
      { leading: true }
    ),
    handleMobileScroll() {
      this.scrollTop = this.getScrollTop();

      if (this.scrollTop > this.prevScrollTop) {
        // Scrolling down, hide
        this.hidden = true;
      } else {
        // Scrolling up, show
        if (this.alerts) this.showAlerts();
        this.hidden = false;
      }

      this.prevScrollTop = this.scrollTop;
    },
    showAlerts() {
      // Only show global alert when close to the top
      if (this.scrollTop < NAV_HEIGHT_MOBILE) {
        this.hiddenAlerts = false;
      } else {
        this.hiddenAlerts = true;
      }
    },
    getScrollTop() {
      return Math.max(
        window.scrollY,
        document.documentElement.scrollTop,
        document.body.scrollTop
      );
    },
    hiddenHeight() {
      return this.alertsHeight + NAV_HEIGHT;
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../sass/component.scss";

.v-navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1001;
  transition: transform 250ms ease-in-out;
  background-color: theme-color("white");

  &__content {
    height: 60px;

    @include media-breakpoint-up(md) {
      height: 76px;
    }

    .row {
      padding-top: 8px;

      @include media-breakpoint-up(md) {
        padding-top: 0px;
      }
    }
  }

  &__alerts {
    background: theme-color("primary");
    position: fixed;
    z-index: 1000;
    top: 76px;
    right: 0;
    transition: all 300ms ease-out;

    @include media-breakpoint-down(sm) {
      top: 60px;
    }

    @media print {
      display: none;
    }
  }

  &__alerts--expanded {
    position: initial;
    z-index: 1000;
    top: 0;
    right: 0;
    transition: all 300ms ease-out;
  }

  @media print {
    position: relative;
  }
}
</style>
