<template>
  <portal :to="globalTarget">
    <div v-if="show" class="v-alert-global">
      <div class="v-alert-global__container container-fluid pr-2">
        <div class="v-alert-global__content">
          <slot></slot>
        </div>
        <div class="v-alert-global__icon">
          <svg-icon v-if="dismissible" close @click="dismiss()" />
        </div>
      </div>
    </div>
  </portal>
</template>

<script>
import Vue from "vue";
import VueCookies from "vue-cookies";
import SvgIcon from "./svg-icon";

Vue.use(VueCookies);

export default {
  components: { "svg-icon": SvgIcon },
  props: {
    expanded: {
      type: Boolean,
      default: true
    },
    dismissible: {
      type: Boolean,
      default: false
    },
    dismissibleId: {
      type: String,
      default: null
    },
    globalTarget: {
      type: String,
      default: "globalAlerts"
    }
  },
  data() {
    return {
      show: true,
      alertKey: "dismissedAlerts"
    };
  },
  mounted() {
    //check if alert has cookie and if so, hide it
    const alerts = JSON.parse(localStorage.getItem(this.alertKey));
    if (alerts && alerts.includes(this.dismissibleId)) {
      this.show = false;
    }
  },
  methods: {
    dismiss() {
      this.show = false;
      if (!this.dismissibleId) return;
      let savedAlerts = JSON.parse(localStorage.getItem(this.alertKey));
      if (savedAlerts) {
        savedAlerts = savedAlerts + " " + this.dismissibleId;
      } else {
        savedAlerts = this.dismissibleId;
      }
      localStorage.setItem(this.alertKey, JSON.stringify(savedAlerts));
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../sass/component.scss";

.v-alert-global {
  position: relative;
  color: theme-color("white");
  background: linear-gradient(
    180deg,
    theme-color("primary") 0%,
    darken(theme-color("primary"), 4%) 70%
  );

  & + .v-alert-global {
    border-top: 1px solid theme-color("white");
  }

  &__container {
    display: flex;
  }

  &__icon {
    position: absolute;
    inset-inline-end: 0.8 * $spacer;
    svg {
      cursor: pointer;
      position: relative;
      inset-block-start: 0.6 * $spacer;
      border-radius: 4px;
      transition: background-color 100ms, box-shadow 200ms;
      &:hover {
        background: lighten(theme-color("primary"), 20%);
        box-shadow: 0 0 0 10px * 0.5 lighten(theme-color("primary"), 20%);
      }
    }
  }

  &__content {
    width: 100%;
    padding: (0.8 * $spacer) 0;
    overflow: hidden;

    @include media-breakpoint-up(md) {
      text-align: center;
    }

    ::v-deep {
      a,
      a:hover,
      .v-text {
        color: theme-color("white");
        font-weight: normal;
      }
      a {
        cursor: pointer;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
</style>
